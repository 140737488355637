












































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Ax } from '@/utils';
import { store } from '../common';
import { ListItem, ListItemPart, Overlay } from '../components';
import I18n from '../I18n';
import { SingleOrgField } from '../organizations';
import {BudgetVariants, Comp, Dict, Org} from '../types';
import ConfirmModal from "@/modules/budget/staffing-table/components/ConfirmModal.vue";


const i18n = new I18n('modules.budget.staffing_table.versions');

interface Version {
    id: number;
    year: number;
    title: string;
    newTitle?: string;  // Присваиваем новое имя заголовка
    budgetVariant: BudgetVariants | null
}


@Component({
    components: {
        ConfirmModal,
        ListItem,
        ListItemPart,
        Overlay,
        SingleOrgField,
    },
})
export default class Page extends Vue {

  get variantState(): boolean {
    return this.budgetVariant !== null;
  }

    // region Lifecycle
    private created() {
        // region Org
        this.$watch('org', (org: Org | null) => {
            store.org = org;
            store.conditionExp = null
            this.idRegion  = this.requiredRegionId
            this.selectedVersion = null;
            this.resetVersions();
        });

        this.$watch('paramsModalYear', () => {
          if (this.paramsModalYear !== 0)
             this.reloadBudgetVariant()
             this.budgetVariant = null
      })
        // endregion
    }

    private mounted() {
        this.resetVersions();
    }
    // endregion


    // region Utils
    private i18n = i18n;

    private get loading(): boolean {
        return this.versionsLoading;
    }

    private toast(type: 'danger' | 'warning' | 'success', title: string, message: string) {
        this.$bvToast.toast(message, {
            title: title,
            variant: type,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }
    // endregion


    // region Org
    private org: Org | null = store.org;
    // endregion

    // confirmModal
    private showConfirmModal: boolean = false
    private closeConfirmModal() {
        this.showConfirmModal = false
    }
     deleteVersion() {
        this.showConfirmModal = true
    }


    // region Modal window - year and title for new version
    private paramsModalYear: number = 0;
    private paramsModalTitle: string = '';
    private paramsModalVisible = false;

    private onParamsModalOk() {
        this.continueVersionCreation();
    }
    // endregion


    // region Versions
    // noinspection JSMismatchedCollectionQueryUpdate
    private versions: Array<Version> = [];
    private versionsLoading = false;
    private selectedVersion: Version | null = null;
    private oldVersion: Version | null = null;

    private resetVersions() {
        if (this.org === null) {
            this.versions = [];
        } else {
            this.reloadVersions();
        }
    }

    private reloadVersions() {
        if (this.versionsLoading) {
            console.error('Cannot reload versions - another loading is running');
            return;
        }

        const org = this.org;
        if (org === null) {
            console.error('Cannot reload versions - organization is NULL');
            return;
        }

        this.versions = [];
        this.versionsLoading = true;
        this.selectedVersion = null;
        Ax<Array<Version>>(
            {
                url: `/api/budget/staffing_table/version/list?org-code=${encodeURIComponent(org.org.code)}`,
            },
            (data) => {
                this.versions = data;
            },
            (error) => {
                this.toast(
                    'danger',
                    this.i18n.translate('error.cannot_load_versions'),
                    error.toString(),
                )
            },
            () => {
                this.versionsLoading = false;
            },
        );
    }

    private createVersion(oldVersion?: Version) {
        this.oldVersion = oldVersion ?? null;
        this.paramsModalYear = oldVersion?.year ?? new Date().getFullYear();
        this.paramsModalTitle = oldVersion?.title ?? '';
        this.budgetVariant = oldVersion?.budgetVariant ?? null
        this.paramsModalVisible = true;
    }

    private continueVersionCreation() {
        if (this.versionsLoading) {
            console.error('Cannot create version - another loading is running');
            return;
        }

        const org = this.org;
        if (org === null) {
            console.error('Cannot create version - organization is NULL');
            return;
        }

       const budgetVariantId = this.budgetVariant?.id
       if (budgetVariantId === null) {
        console.error('Cannot create version - variantId is NULL');
        return;
        }

        const year = this.paramsModalYear;
        const title = this.paramsModalTitle.trim();
        const oldVersion = this.oldVersion;

        let url = `/api/budget/staffing_table/version?org-code=${encodeURIComponent(org.org.code)}&year=${year}&budget-variantId=${budgetVariantId}`;
        if (title.length > 0) {
            url += `&title=${encodeURIComponent(title)}`;
        }
        if (oldVersion !== null) {
            url += `&old-id=${oldVersion.id}`;
        }

        this.versionsLoading = true;
        Ax<Version>(
            {
                url: url,
                method: 'POST',
            },
            (data) => {
                const id = data.id;
                const year = data.year;
                this.toast(
                    'success',
                    '',
                    this.i18n.translate('success.created', [id, year]),
                );
            },
            (error) => {
                this.toast(
                    'danger',
                    this.i18n.translate('error.cannot_create_new_version'),
                    error.toString(),
                )
            },
            () => {
                this.versionsLoading = false;
                this.budgetVariant = null
                this.resetVersions();
            },
        );
    }

  // region Budget Variant
  private budgetVariants: Array<BudgetVariants> = [];
  private budgetVariantsLoading = false;
  private budgetVariant: BudgetVariants | null = null;

  private idRegion: string | null = this.requiredRegionId;

  private get selectedGu(): Dict.Gu | null {
    if (this.org === null) return null;
    return this.org.gu;
  }

  private get budgetVariantOptions(): Array<Comp.DropdownItemDef<BudgetVariants>> {
    return this.budgetVariants.map((variant) => {
      const status = variant.status ? '(утв)' : variant.attribute ? '(акт)' : '';

      const title = this.i18n.locale.trim().toLowerCase() === 'kk'
          ? variant.nameKk ?? variant.nameRu
          : this.i18n.locale.trim().toLowerCase() === 'en'
              ? variant.nameEn ?? variant.nameRu
              : variant.nameRu;
      return {value: variant, text: `${title} ${status}`};
    }).sort((v1, v2) => v1.text.localeCompare(v2.text));
  }

  private getVariantTitle(budgetVariant: BudgetVariants | null): string {
    if (budgetVariant === null) return ''
    const status = budgetVariant.status ? '(утв)' : budgetVariant.attribute ? '(акт)' : '';
    const title = this.i18n.locale.trim().toLowerCase() === 'kk' ? budgetVariant.nameKk ?? budgetVariant.nameRu
        : this.i18n.locale.trim().toLowerCase() === 'en' ?
            budgetVariant.nameEn ?? budgetVariant.nameRu : budgetVariant.nameRu;
    return `${title} ${status}`
  }

  private get requiredRegionId(): string | null {
    const gu = this.selectedGu;
    if (gu === null) return null;

    let result: string;
    if (gu.budgetType === '02') {
      const idRegion = gu.idRegion;
      const idRegionPart = (
          idRegion.length > 2 ?
              idRegion.substr(0, 2)
              :
              idRegion
      );
      result = idRegionPart + '0101';
    } else {
      result = gu.idRegion;
    }

    return result;
  }

  private reloadBudgetVariant() {

    if (this.budgetVariantsLoading) {
      console.error('Cannot reload budgetVariant - another loading is running');
      return;
    }

    if (this.idRegion == null) {
      console.error('Cannot load budgetVariants  - idRegion is null');
      return;
    }

    const year = this.paramsModalYear;
    const regionCode = this.idRegion

    this.budgetVariantsLoading = true;
    Ax<BudgetVariants[]>(
        { url: `/api/budget/staffing_table/budget-variants-storage?year=${year}&region-code=${regionCode}` },
        data => {
          this.budgetVariants = data;
        },
        error => {
          this.toast('danger', 'Loading budget variants', `Error while loading budget variants - ${error}`);
        },
        () => { this.budgetVariantsLoading = false; }
    );
  }

    private onVersionClick(version: Version) {
        if (this.selectedVersion === version) {
            this.selectedVersion = null;
        } else {
            this.selectedVersion = version;
        }
    }

    private onCreateNewClick() {
        this.createVersion();
        this.reloadBudgetVariant()
    }

    private onCopyClick() {
        const selectedVersion = this.selectedVersion;
        if (selectedVersion === null) {
            console.error('Cannot copy version - selected version is NULL');
            return;
        }

        this.createVersion(selectedVersion);
    }

    private onDeleteClick() {
        if (this.versionsLoading) {
            console.error('Cannot delete version - another loading is running');
            return;
        }

        const selectedVersion = this.selectedVersion;
        if (selectedVersion === null) {
            console.error('Cannot delete version - selected version is NULL');
            return;
        }
        const id = selectedVersion.id;

        this.showConfirmModal = false
        this.versionsLoading = true;
        Ax(
            {
                url: `/api/budget/staffing_table/version?id=${id}`,
                method: 'DELETE',
            },
            () => {
                this.toast(
                    'success',
                    '',
                    this.i18n.translate('success.deleted', [id]),
                )
            },
            (error) => {
                this.toast(
                    'danger',
                    this.i18n.translate('error.cannot_delete_version', [id]),
                    error.toString(),
                );
            },
            () => {
                this.versionsLoading = false;
                this.resetVersions();
            },
        );
    }

    private setVersionName(version: Version) {
        const newName = version.newTitle ?? null
        const idVersion = version.id

        if (newName == null) {
            console.error('Cannot set version title  - newTitle is null');
            return;
        }
        Ax(
            {
                url: '/api/budget/staffing_table/version/newTitle',
                method: 'PUT',
                params: {
                    newTitle: newName,
                    idVersion: idVersion
                }
            },
            () => {
                version.title = newName
                this.toast(
                    'success',
                    '',
                    this.i18n.translate('success.saved', [newName]),
                )
            },
            (error) => {
                this.toast(
                    'danger',
                    this.i18n.translate('error.cannot_save_newTitle_version'),
                    error.toString(),
                )
            },
            () => {
                version.newTitle = ''
            },
        );
    }
    // endregion
}
